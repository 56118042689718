<template>
    <pre class="content" v-html="ffInfo.body"></pre>
</template>
<script>
    export default {
        data() {
            return {
                id: "",
                ffInfo: {},
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData();
        },
        methods: {
            async getData() {
                this.id = this.$route.query.id;
                const {
                    data
                } = await this.$y_ajax("api/page/view", {
                    id: this.id,
                });
                if (data) {
                    this.ffInfo = data;
                    this.setMeta(data.title, 1);
                }
            },
        },
        watch: {
            $route() {
                this.getData();
            },
        },
    };
</script>
<style lang="less" scoped>
    .content {
        line-height: 1.5;
        font-size: 16px;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .content {
            width: 90vw;
            margin-left: 5vw;
        }
    }
</style>